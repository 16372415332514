/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons6 = ({ className }) => {
  return (
    <svg
      className={`icons-6 ${className}`}
      fill="none"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="15"
        id="mask0_206_1327"
        maskUnits="userSpaceOnUse"
        // style="mask-type:alpha"
        width="15"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="13.5501" width="13.5501" x="0.702515" y="0.868713" />
      </mask>
      <g className="g" mask="url(#mask0_206_1327)">
        <path
          className="path"
          d="M7.4776 9.96097C8.10913 9.96097 8.64602 9.73577 9.08826 9.28537C9.53016 8.83463 9.75111 8.28743 9.75111 7.64376C9.75111 7.0001 9.53016 6.4529 9.08826 6.00216C8.64602 5.55176 8.10913 5.32656 7.4776 5.32656C6.84607 5.32656 6.30919 5.55176 5.86695 6.00216C5.42505 6.4529 5.2041 7.0001 5.2041 7.64376C5.2041 8.28743 5.42505 8.83463 5.86695 9.28537C6.30919 9.73577 6.84607 9.96097 7.4776 9.96097ZM7.4776 8.96972C7.11553 8.96972 6.80818 8.84099 6.55557 8.58352C6.30296 8.32605 6.17665 8.0128 6.17665 7.64376C6.17665 7.27473 6.30296 6.96148 6.55557 6.70401C6.80818 6.44654 7.11553 6.31781 7.4776 6.31781C7.83968 6.31781 8.14703 6.44654 8.39964 6.70401C8.65225 6.96148 8.77855 7.27473 8.77855 7.64376C8.77855 8.0128 8.65225 8.32605 8.39964 8.58352C8.14703 8.84099 7.83968 8.96972 7.4776 8.96972ZM7.4776 11.5959C6.22296 11.5959 5.08419 11.2375 4.06128 10.5207C3.03803 9.80425 2.29485 8.84528 1.83173 7.64376C2.29485 6.44225 3.03803 5.4831 4.06128 4.76632C5.08419 4.04987 6.22296 3.69165 7.4776 3.69165C8.73224 3.69165 9.87119 4.04987 10.8944 4.76632C11.9173 5.4831 12.6604 6.44225 13.1235 7.64376C12.6604 8.84528 11.9173 9.80425 10.8944 10.5207C9.87119 11.2375 8.73224 11.5959 7.4776 11.5959ZM7.4776 10.4759C8.42911 10.4759 9.30062 10.2205 10.0921 9.70968C10.8837 9.19921 11.4899 8.51057 11.9109 7.64376C11.4899 6.77696 10.8837 6.08815 10.0921 5.57734C9.30062 5.06687 8.42911 4.81163 7.4776 4.81163C6.5261 4.81163 5.65459 5.06687 4.86307 5.57734C4.07155 6.08815 3.46528 6.77696 3.04426 7.64376C3.46528 8.51057 4.07155 9.19921 4.86307 9.70968C5.65459 10.2205 6.5261 10.4759 7.4776 10.4759Z"
          fill="#929191"
        />
      </g>
    </svg>
  );
};
