/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { User } from "../../icons/User";
import { User18 } from "../../icons/User18";
import { User27 } from "../../icons/User27";
import { User36 } from "../../icons/User36";
import { User9 } from "../../icons/User9";
import { AvatarOnline } from "../AvatarOnline";
import "./style.css";

export const Avatar = ({
  size,
  placeholder,
  text,
  statusIcon,
  state,
  className,
  avatarOnlineSizeXsOnlineFalseClassName,
  hasText = true,
}) => {
  return (
    <div
      className={`avatar text-${text} placeholder-${placeholder} state-3-${state} size-${size} ${statusIcon} ${className}`}
    >
      {((placeholder && statusIcon === "false") ||
        (size === "lg" && statusIcon === "online-indicator") ||
        (size === "md" && statusIcon === "online-indicator") ||
        (size === "sm" && statusIcon === "online-indicator") ||
        (size === "two-xl" && statusIcon === "online-indicator" && !text) ||
        (size === "xl" && statusIcon === "online-indicator") ||
        (size === "xs" && statusIcon === "online-indicator") ||
        statusIcon === "company" ||
        (statusIcon === "false" && text)) && (
        <>
          <>
            {hasText && (
              <>
                <>
                  {((!placeholder && statusIcon === "false") ||
                    (placeholder && statusIcon === "online-indicator") ||
                    statusIcon === "company" ||
                    (statusIcon === "online-indicator" && text)) && (
                    <div className="text-6">
                      {statusIcon === "false" && <>OR</>}

                      {text && ["company", "online-indicator"].includes(statusIcon) && <div className="text">OR</div>}

                      {placeholder && ["two-xl", "xl"].includes(size) && <User36 className="user-36" />}

                      {((placeholder && size === "two-xl" && statusIcon === "company") ||
                        (placeholder && size === "xl" && statusIcon === "company") ||
                        (statusIcon === "company" && text)) && <div className="avatar-company-icon" />}

                      {((size === "two-xl" && statusIcon === "online-indicator") ||
                        (size === "xl" && statusIcon === "online-indicator" && !text) ||
                        (statusIcon === "online-indicator" && text)) && (
                        <AvatarOnline
                          className={`${
                            size === "two-xl"
                              ? "class"
                              : size === "xl" && text
                              ? "class-2"
                              : size === "lg"
                              ? "class-3"
                              : size === "md"
                              ? "class-4"
                              : size === "sm"
                              ? "class-5"
                              : size === "xs"
                              ? "class-6"
                              : "class-7"
                          }`}
                          online
                          size={
                            size === "two-xl"
                              ? "two-xl"
                              : size === "lg"
                              ? "lg"
                              : size === "md"
                              ? "md"
                              : size === "sm"
                              ? "sm"
                              : size === "xs"
                              ? "xs"
                              : "xl"
                          }
                        />
                      )}

                      {size === "lg" && placeholder && <User27 className="user-27" />}

                      {size === "lg" && placeholder && statusIcon === "company" && (
                        <div className="avatar-company-icon-2" />
                      )}

                      {size === "lg" && statusIcon === "online-indicator" && !text && (
                        <AvatarOnline className="avatar-online-indicator" online size="lg" />
                      )}

                      {size === "md" && placeholder && <User18 className="user-18" />}

                      {size === "md" && statusIcon === "company" && placeholder && (
                        <div className="avatar-company-icon-3" />
                      )}

                      {statusIcon === "online-indicator" && size === "md" && !text && (
                        <AvatarOnline className="avatar-online-instance" online size="md" />
                      )}

                      {placeholder && size === "sm" && <User9 className="user-9" />}

                      {placeholder && statusIcon === "company" && size === "sm" && (
                        <div className="avatar-company-icon-4" />
                      )}

                      {statusIcon === "online-indicator" && size === "sm" && !text && (
                        <AvatarOnline className="avatar-online-indicator-instance" online size="sm" />
                      )}

                      {size === "xs" && placeholder && <User className="user-instance" />}

                      {size === "xs" && placeholder && statusIcon === "company" && (
                        <div className="avatar-company-icon-5" />
                      )}

                      {size === "xs" && statusIcon === "online-indicator" && !text && (
                        <AvatarOnline className="instance-node" online size="xs" />
                      )}
                    </div>
                  )}

                  {!text && statusIcon === "false" && ["two-xl", "xl"].includes(size) && (
                    <User36 className={`${size === "two-xl" ? "class-8" : "class-9"}`} />
                  )}

                  {size === "lg" && statusIcon === "false" && !text && <User27 className="user-27-instance" />}

                  {statusIcon === "false" && size === "md" && !text && <User18 className="user-18-instance" />}

                  {statusIcon === "false" && size === "sm" && !text && <User9 className="user-9-instance" />}

                  {size === "xs" && statusIcon === "false" && !text && <User className="user-2" />}

                  {!placeholder && statusIcon === "online-indicator" && !text && (
                    <AvatarOnline
                      className={avatarOnlineSizeXsOnlineFalseClassName}
                      online
                      size={
                        size === "two-xl"
                          ? "two-xl"
                          : size === "lg"
                          ? "lg"
                          : size === "md"
                          ? "md"
                          : size === "sm"
                          ? "sm"
                          : size === "xs"
                          ? "xs"
                          : "xl"
                      }
                    />
                  )}
                </>
              </>
            )}
          </>
        </>
      )}

      {text && statusIcon === "online-indicator" && size === "two-xl" && (
        <>
          <div className="text-2">OR</div>
          <AvatarOnline className="avatar-online-2" online size="two-xl" />
        </>
      )}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(["xl", "xs", "lg", "two-xl", "sm", "md"]),
  placeholder: PropTypes.bool,
  text: PropTypes.bool,
  statusIcon: PropTypes.oneOf(["company", "false", "online-indicator"]),
  state: PropTypes.oneOf(["focused", "hover", "default"]),
  hasText: PropTypes.bool,
};
