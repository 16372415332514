/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconLogoutFill = ({ className, iconLogoutFill = "/img/icon-logout-fill-false.png" }) => {
  return <img className={`icon-logout-fill ${className}`} alt="Icon logout fill" src={iconLogoutFill} />;
};

IconLogoutFill.propTypes = {
  iconLogoutFill: PropTypes.string,
};
