/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Icons20 } from "../../icons/Icons20";
import { Icons21 } from "../../icons/Icons21";
import { Icons22 } from "../../icons/Icons22";
import { Icons23 } from "../../icons/Icons23";
import { Icons24 } from "../../icons/Icons24";
import "./style.css";

export const Button = ({ type, size, className, buttonBaseClassName, textClassName, text = "Button CTA" }) => {
  return (
    <button className={`button type-${type} ${size} ${className}`}>
      <div className={`button-base ${buttonBaseClassName}`}>
        {["big", "large", "medium", "small", "x-small"].includes(size) && (
          <div
            className={`text-3 ${
              (size === "big" && type === "primary") ||
              (size === "medium" && type === "primary") ||
              (size === "small" && type === "primary") ||
              (size === "x-small" && type === "primary") ||
              type === "error" ||
              type === "outlined" ||
              type === "secondary" ||
              type === "text"
                ? textClassName
                : undefined
            }`}
          >
            {text}
          </div>
        )}

        {type === "primary" && size === "tiny" && <Icons24 className="icons-2" />}

        {type === "secondary" && size === "tiny" && <Icons23 className="icons-2" />}

        {type === "error" && size === "tiny" && <Icons22 className="icons-2" />}

        {type === "outlined" && size === "tiny" && <Icons21 className="icons-2" />}

        {type === "text" && size === "tiny" && <Icons20 className="icons-2" />}
      </div>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["secondary", "primary", "text", "outlined", "error"]),
  size: PropTypes.oneOf(["large", "x-small", "tiny", "big", "small", "medium"]),
  text: PropTypes.string,
};
