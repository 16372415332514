/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons5 = ({ className }) => {
  return (
    <svg
      className={`icons-5 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="14"
        id="mask0_206_1338"
        maskUnits="userSpaceOnUse"
        // style="mask-type:alpha"
        width="14"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="13.4837" width="13.4837" x="0.0994568" y="0.266357" />
      </mask>
      <g className="g" mask="url(#mask0_206_1338)">
        <path
          className="path"
          d="M6.84132 9.31404C7.46975 9.31404 8.00401 9.08995 8.44408 8.64176C8.88382 8.19323 9.10369 7.64871 9.10369 7.00819C9.10369 6.36768 8.88382 5.82316 8.44408 5.37463C8.00401 4.92644 7.46975 4.70234 6.84132 4.70234C6.21288 4.70234 5.67863 4.92644 5.23855 5.37463C4.79882 5.82316 4.57895 6.36768 4.57895 7.00819C4.57895 7.64871 4.79882 8.19323 5.23855 8.64176C5.67863 9.08995 6.21288 9.31404 6.84132 9.31404ZM6.84132 8.32765C6.48101 8.32765 6.17518 8.19955 5.9238 7.94334C5.67243 7.68714 5.54674 7.37542 5.54674 7.00819C5.54674 6.64096 5.67243 6.32925 5.9238 6.07304C6.17518 5.81684 6.48101 5.68873 6.84132 5.68873C7.20162 5.68873 7.50746 5.81684 7.75883 6.07304C8.01021 6.32925 8.1359 6.64096 8.1359 7.00819C8.1359 7.37542 8.01021 7.68714 7.75883 7.94334C7.50746 8.19955 7.20162 8.32765 6.84132 8.32765ZM6.84132 10.9409C5.59282 10.9409 4.45963 10.5843 3.44173 9.87103C2.4235 9.1581 1.68395 8.20382 1.2231 7.00819C1.68395 5.81257 2.4235 4.85812 3.44173 4.14484C4.45963 3.43191 5.59282 3.07544 6.84132 3.07544C8.08981 3.07544 9.22317 3.43191 10.2414 4.14484C11.2593 4.85812 11.9987 5.81257 12.4595 7.00819C11.9987 8.20382 11.2593 9.1581 10.2414 9.87103C9.22317 10.5843 8.08981 10.9409 6.84132 10.9409ZM6.84132 9.82645C7.78816 9.82645 8.6554 9.5723 9.44304 9.06398C10.2307 8.55602 10.834 7.87075 11.2529 7.00819C10.834 6.14563 10.2307 5.4602 9.44304 4.95189C8.6554 4.44392 7.78816 4.18993 6.84132 4.18993C5.89447 4.18993 5.02723 4.44392 4.23959 4.95189C3.45195 5.4602 2.84865 6.14563 2.4297 7.00819C2.84865 7.87075 3.45195 8.55602 4.23959 9.06398C5.02723 9.5723 5.89447 9.82645 6.84132 9.82645Z"
          fill="#929191"
        />
      </g>
    </svg>
  );
};
