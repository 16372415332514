import React, {useEffect} from "react";
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { IconEditFillFalse } from "../../components/IconEditFillFalse";
import { IconHelpFillFalse } from "../../components/IconHelpFillFalse";
import { IconLogoutFill } from "../../components/IconLogoutFill";
import { IconMenuFillFalse } from "../../components/IconMenuFillFalse";
import { IconStarCircle } from "../../components/IconStarCircle";
import { InputField } from "../../components/InputField";
import { TabBar } from "../../components/TabBar";
import { TypeDefaultSize } from "../../components/TypeDefaultSize";
import { TypeFiArrowLeft } from "../../components/TypeFiArrowLeft";
import { Icons5 } from "../../icons/Icons5";
import { Icons6 } from "../../icons/Icons6";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="div-2">
        <div className="overlap">
          <div className="element-mockup">
            <div className="overlap-group">
              <img className="rectangle" alt="Rectangle" src="/img/rectangle-10.png" />
              <div className="frame-2">
                <div className="text-wrapper-3">Defining the Architecture.</div>
                <p className="p">
                  Most of the users faced trouble in navigating and found the system to be overwhelming - especially the
                  new users. To better structure the flows and make the learning curve lean - we organised the
                  unstructured modules with smart flows - Guide based tours and usage / user-based dashboards.
                </p>
              </div>
            </div>
          </div>
          <div className="frame-3">
            <div className="frame-4">
              <div className="div-wrapper">
                <div className="div-wrapper">
                  <div className="text-wrapper-4">Research and Identify</div>
                </div>
              </div>
              <p className="after-a-series-of">
                After a series of holistic UX audits, assessments - back and forth we started with first understanding
                the market, the competition. We figured to better navigate and get a firm understanding let&#39;s talk
                to the Users themselves.
                <br />
                <br />
                <br />
                As the legend goes, &#34;talking to 5 users is better than none&#34;. We did just that and uncovered
                deeper insights. But, instead of personas, we dissected the users further based on need and usage to
                better grasp and address the actual problems users faced, helping us paint a much better flow for the
                protagonist - the user.
              </p>
            </div>
            <img className="img" alt="Element" src="/img/4.png" />
            <img className="img-2" alt="Element" src="/img/2.png" />
            <div className="frame-5">
              <div className="overlap-2">
                <div className="overlap-group-2">
                  <img className="connector-line" alt="Connector line" src="/img/connector-line-1.png" />
                  <img className="connector-line-2" alt="Connector line" src="/img/connector-line.png" />
                  <img
                    className="not-having-the-time"
                    alt="Not having the time"
                    src="/img/not-having-the-time-and-availability-to-go-through-training-pr.svg"
                  />
                  <img
                    className="lightweight-needs-to"
                    alt="Lightweight needs to"
                    src="/img/lightweight-needs-to-work-on-slower-internet-too.svg"
                  />
                  <img
                    className="confusing-some-games"
                    alt="Confusing some games"
                    src="/img/confusing-some-games-seems-too-simple.svg"
                  />
                  <img className="populated-needs" alt="Populated needs" src="/img/populated-needs-simplicity.svg" />
                  <img
                    className="difficult-with"
                    alt="Difficult with"
                    src="/img/difficult-with-different-browsers.svg"
                  />
                </div>
                <img
                  className="practice-sessions"
                  alt="Practice sessions"
                  src="/img/practice-sessions-takes-too-long.svg"
                />
                <img className="seems-intense" alt="Seems intense" src="/img/seems-intense.svg" />
                <img
                  className="pricing-seems-to-be"
                  alt="Pricing seems to be"
                  src="/img/pricing-seems-to-be-a-bit-high.svg"
                />
                <img
                  className="not-as-intuitive-as"
                  alt="Not as intuitive as"
                  src="/img/not-as-intuitive-as-it-goes.svg"
                />
                <img
                  className="testing-seems-to-be"
                  alt="Testing seems to be"
                  src="/img/testing-seems-to-be-a-challenge-while-building.svg"
                />
                <img
                  className="effortless-link-code"
                  alt="Effortless link code"
                  src="/img/effortless-link-code-sharing.svg"
                />
                <img className="users-login" alt="Users login" src="/img/users-login.svg" />
                <img
                  className="mobile-friendliness"
                  alt="Mobile friendliness"
                  src="/img/mobile-friendliness-for-live-sessions.svg"
                />
                <img className="finding-things-is" alt="Finding things is" src="/img/finding-things-is-not-easy.svg" />
                <img
                  className="troubleshooting-is"
                  alt="Troubleshooting is"
                  src="/img/troubleshooting-is-an-issue.svg"
                />
                <img
                  className="complex-as-it-goes"
                  alt="Complex as it goes"
                  src="/img/complex-as-it-goes-difficult-to-find-created.svg"
                />
                <img className="difficult-to" alt="Difficult to" src="/img/difficult-to-navigate-comprehend.svg" />
                <img className="relevant-dynamic" alt="Relevant dynamic" src="/img/relevant-dynamic-updates.svg" />
              </div>
              <div className="text-wrapper-5">Learnablity</div>
              <div className="text-wrapper-6">Adaptive</div>
              <div className="text-wrapper-7">Usablity</div>
              <div className="text-wrapper-8">Legend:</div>
              <div className="text-wrapper-9">Accessiblity</div>
              <div className="frame-6">
                <div className="ellipse" />
                <div className="text-wrapper-10">User 1</div>
              </div>
              <div className="frame-7">
                <div className="ellipse-2" />
                <div className="text-wrapper-10">User 2</div>
              </div>
              <div className="frame-8">
                <div className="ellipse-3" />
                <div className="text-wrapper-10">User 3</div>
              </div>
              <div className="frame-9">
                <div className="ellipse-4" />
                <div className="text-wrapper-10">User 4</div>
              </div>
              <div className="frame-10">
                <div className="ellipse-5" />
                <div className="text-wrapper-11">User 5</div>
              </div>
            </div>
          </div>
          <img className="headshot" alt="Headshot" src="/img/headshot.png" />
        </div>
        <div className="frame-11">
          <div className="text-wrapper-12">Webapp</div>
          <div className="text-wrapper-13">SAAS, Edtech</div>
        </div>
        <div className="frame-12">
          <div className="text-wrapper-14">Gaminar</div>
          <div className="gamifying-learnings">
            Gamifying learnings
            <br />
            with engaging webinars
          </div>
        </div>
        <div className="group">
          <div className="frame-13">
            <div className="UIX">
              <div className="frame-wrapper">
                <div className="frame-14">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <img className="line-2" alt="Line" src="/img/line-18.svg" />
                      <img className="line-3" alt="Line" src="/img/line-19.svg" />
                    </div>
                  </div>
                  <div className="text-wrapper-15">SAAS</div>
                </div>
              </div>
            </div>
            <div className="UIX-2">
              <div className="frame-wrapper">
                <div className="frame-14">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <img className="line-2" alt="Line" src="/img/line-18.svg" />
                      <img className="line-3" alt="Line" src="/img/line-19.svg" />
                    </div>
                  </div>
                  <div className="text-wrapper-15">Web-app Design</div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-16">Industry</div>
        </div>
        <div className="overlap-3">
          <div className="dashboard-new-user-wrapper">
            <img className="dashboard-new-user" alt="Dashboard new user" src="/img/dashboard-new-user.png" />
          </div>
          <div className="about">
            <div className="container">
              <div className="about-the-client">
                <div className="text-wrapper-17">About the client</div>
                <p className="text-wrapper-18">
                  Gaminar.net enables facilitators to deliver virtual, hybrid, and face-to-face experiential learning
                  programs and team building activities using simulations and gamified webinars to users all over the
                  globe with their platform.
                </p>
              </div>
              <div className="the-challenge">
                <div className="text-wrapper-19">The Challenge</div>
                <p className="gaminar-wanted-us-to">
                  Gaminar wanted us to give their web application a much-needed makeover - one that would increase
                  conversions and keep the users retained to help users set up and manage webinars more swiftly and
                  easily. The goal for tis was to increase the he number of active users with seamless &amp; easy user
                  interface and redefine the bars for a seamless experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-20">Simplified Onboarding</div>
        <div className="sign-in">
          <div className="header">
            <div className="logo">
              <img className="image" alt="Image" src="/img/image-34-2.png" />
            </div>
          </div>
          <div className="section-form">
            <div className="section-signup">
              <div className="container-fields">
                <div className="container-form">
                  <div className="container-header">
                    <div className="header-2">
                      <div className="text-wrapper-21">Log in</div>
                      <p className="text-wrapper-22">
                        Good to see you again! let’s get creating mind-boggling gaminars.
                      </p>
                    </div>
                    <div className="container-social">
                      <div className="text-input">
                        <div className="frame-15">
                          <img className="img-3" alt="Google g logo" src="/img/google-g-logo-1-1.svg" />
                          <div className="frame-16">
                            <div className="text-4">Continue with Google</div>
                          </div>
                        </div>
                      </div>
                      <div className="text-input-2">
                        <div className="frame-17">
                          <img className="img-3" alt="Frame" src="/img/frame-4107-1.svg" />
                          <div className="frame-16">
                            <div className="text-4">Continue with Linkedin</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-3">
                    <img className="line-4" alt="Line" src="/img/line-70-1.svg" />
                    <p className="text-wrapper-23">or Sign in with Email</p>
                    <img className="line-4" alt="Line" src="/img/line-71-1.svg" />
                  </div>
                  <div className="container-fields-2">
                    <div className="div-4">
                      <div className="text-wrapper-24">Email Address</div>
                      <InputField
                        className="input-field-instance"
                        frameClassName="design-component-instance-node"
                        property="default"
                        stateProp="default"
                      />
                    </div>
                    <div className="div-4">
                      <div className="text-wrapper-24">Password</div>
                      <InputField
                        className="input-field-2"
                        divClassName="design-component-instance-node"
                        icon={<Icons6 className="img-3" />}
                        property="input-icon"
                        stateProp="default"
                      />
                    </div>
                  </div>
                </div>
                <button className="CTA">
                  <div className="text-wrapper-25">Continue</div>
                </button>
              </div>
              <div className="frame-18">
                <div className="frame-19">
                  <Checkbox
                    className="checkbox-instance"
                    stateProp="enabled"
                    states="/img/icons.png"
                    statesClassName="checkbox-2"
                    variant="deselected"
                  />
                  <div className="login">Remember Me</div>
                </div>
                <div className="frame-19">
                  <div className="login">Forgot Password?</div>
                </div>
              </div>
            </div>
            <div className="login-2">
              <div className="login-3">New here?</div>
              <div className="login-4">Create an account</div>
            </div>
          </div>
        </div>
        <div className="sign-up">
          <div className="overlap-4">
            <div className="section-form-2">
              <div className="container-section">
                <div className="container-form-2">
                  <div className="container-form-3">
                    <div className="container-header-2">
                      <div className="header-3">
                        <div className="text-wrapper-26">Create an account</div>
                        <p className="text-wrapper-27">
                          Welcome to Gaminar, the most awesome way to gamify your content your way.
                        </p>
                      </div>
                      <div className="container-social-2">
                        <div className="text-input-3">
                          <div className="frame-20">
                            <img className="img-4" alt="Google g logo" src="/img/google-g-logo-1.svg" />
                            <div className="frame-21">
                              <div className="text-4">Continue with Google</div>
                            </div>
                          </div>
                        </div>
                        <div className="text-input-4">
                          <div className="frame-20">
                            <img className="img-4" alt="Frame" src="/img/frame-4107.svg" />
                            <div className="frame-21">
                              <div className="text-4">Continue with Linkedin</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <img className="line-4" alt="Line" src="/img/line-70.svg" />
                      <p className="text-wrapper-28">or Sign in with Email</p>
                      <img className="line-4" alt="Line" src="/img/line-71.svg" />
                    </div>
                    <div className="div-6">
                      <div className="div-7">
                        <InputField
                          className="input-field-3"
                          frameClassName="design-component-instance-node"
                          property="default"
                          stateProp="default"
                        />
                        <div className="text-wrapper-29">Wrong Email Address</div>
                      </div>
                      <div className="div-7">
                        <InputField
                          className="input-field-4"
                          divClassName="design-component-instance-node"
                          icon={<Icons5 className="img-4" />}
                          property="input-icon"
                          stateProp="default"
                        />
                      </div>
                      <div className="container-promo-code">
                        <div className="text-5">Get a promo code?</div>
                        <InputField
                          className="input-field-5"
                          frameClassName="design-component-instance-node"
                          property="default"
                          stateProp="default"
                          text="Enter Code"
                        />
                      </div>
                    </div>
                  </div>
                  <button className="CTA-2">
                    <div className="text-wrapper-30">Continue</div>
                  </button>
                </div>
                <div className="login-5">
                  <div className="login-6">Have an account already?</div>
                  <div className="login-4">Log In</div>
                </div>
              </div>
              <p className="by-clicking-continue">
                <span className="span">By Clicking continue, you agree to Gaminar’</span>
                <span className="text-wrapper-31">s</span>
                <span className="text-wrapper-32">&nbsp;</span>
                <span className="text-wrapper-33">Privacy Policy</span>
                <span className="text-wrapper-34">&nbsp;</span>
                <span className="span">and </span>
                <span className="text-wrapper-33">Terms of Service</span>
              </p>
            </div>
            <div className="frame-22">
              <div className="overlap-group-4">
                <div className="background">
                  <div className="ellipse-6" />
                  <div className="ellipse-7" />
                </div>
                <div className="container-2">
                  <div className="frame-23">
                    <div className="header-4">
                      <div className="emoji">
                        <img className="waving-hand" alt="Waving hand" src="/img/waving-hand-1f44b-1.png" />
                      </div>
                      <p className="ready-to-transform">
                        <span className="text-wrapper-35">
                          Ready to transform
                          <br />
                          your virtual or in-person training into{" "}
                        </span>
                        <span className="text-wrapper-36">immersive learning experiences?</span>
                      </p>
                    </div>
                    <div className="testimonials">
                      <div className="div-6">
                        <img className="review" alt="Review" src="/img/review.svg" />
                        <p className="having-already-run-a">
                          “Having already run a few Gaminars for my clients, I appreciate that this platform helps
                          create immersive experiences for participants, generating powerful reactions, which prompt
                          meaningful debriefs at the end. Moreover, the versatility of different Gaminars means that we
                          can run either dedicated team building events or icebreakers as part of a larger events&#39;
                          agenda.”
                        </p>
                      </div>
                      <div className="frame-24">
                        <div className="user-3">
                          <div className="frame-25">
                            <div className="BG-wrapper">
                              <img className="BG" alt="Bg" src="/img/bg.png" />
                            </div>
                            <div className="frame-26">
                              <div className="text-wrapper-30">Victor Georgescu</div>
                              <p className="founder-at-lifehax">Founder at Lifehax, Event Host &amp; Facilitator</p>
                            </div>
                          </div>
                        </div>
                        <div className="pagination">
                          <img className="line-5" alt="Line" src="/img/line-17.svg" />
                          <div className="ellipse-8" />
                          <div className="ellipse-8" />
                          <div className="ellipse-8" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-wrapper">
              <div className="logo-2">
                <div className="overlap-group-5">
                  <img className="image-2" alt="Image" src="/img/image-34-1.png" />
                  <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-account-report">
          <div className="header-5">
            <div className="frame-27">
              <IconMenuFillFalse className="icon-menu-fill-false-instance" iconMenuFillFalse="/img/icons.png" />
              <div className="image-wrapper">
                <img className="image-3" alt="Image" src="/img/icons.png" />
              </div>
            </div>
            <div className="frame-28">
              <div className="frame-29">
                <div className="text-wrapper-37">Gaminars</div>
                <div className="frame-30">
                  <IconStarCircle className="icon-star-circle-fill-true" iconStarCircle="/img/icons.png" />
                  <div className="text-wrapper-38">Pricing</div>
                </div>
                <div className="text-wrapper-38">Simulations</div>
                <div className="text-wrapper-38">Marketplace</div>
              </div>
              <div className="frame-31">
                <div className="frame-32">
                  <img className="vector" alt="Vector" src="/img/icons.png" />
                  <img className="vector-2" alt="Vector" src="/img/icons.png" />
                  <div className="ellipse-9" />
                </div>
                <TypeDefaultSize
                  avatarAvatarOnlineSizeXsOnlineFalseClassName="type-default-size-default-instance"
                  avatarSizeXlPlaceholderClassName="type-default-size-instance"
                  className="type-default-size-default"
                />
              </div>
            </div>
          </div>
          <div className="frame-33">
            <div className="frame-34">
              <div className="text-wrapper-39">Account Settings</div>
              <div className="frame-35">
                <TabBar className="tab-bar-instance" divClassName="tab-bar-2" stateProp="default" text="Profile" />
                <TabBar className="tab-bar-instance" divClassName="tab-bar-2" stateProp="default" text="Subscription" />
                <TabBar className="tab-bar-instance" divClassName="tab-bar-2" stateProp="default" text="Billing" />
                <TabBar className="tab-bar-3" divClassName="tab-bar-4" stateProp="selected" text="Report" />
                <TabBar className="tab-bar-instance" divClassName="tab-bar-2" stateProp="default" text="Facilitators" />
                <TabBar className="tab-bar-instance" divClassName="tab-bar-2" stateProp="default" text="Simulations" />
                <TabBar className="tab-bar-instance" divClassName="tab-bar-2" stateProp="default" text="Notification" />
                <TabBar className="tab-bar-instance" divClassName="tab-bar-2" stateProp="default" text="Support" />
              </div>
            </div>
            <div className="frame-36">
              <div className="frame-37">
                <div className="frame-34">
                  <div className="frame-38">
                    <div className="frame-29">
                      <div className="text-wrapper-40">Reports</div>
                    </div>
                  </div>
                  <div className="frame-39">
                    <div className="frame-40">
                      <div className="frame-31">
                        <img className="ellipse-10" alt="Ellipse" src="/img/icons.png" />
                        <div className="frame-41">
                          <div className="text-wrapper-41">Activity Reports</div>
                          <p className="text-wrapper-42">
                            Verify all the Gaminars you have done, including credits used, client and number of
                            participants
                          </p>
                        </div>
                      </div>
                      <img className="expand-less" alt="Expand less" src="/img/icons.png" />
                    </div>
                    <img className="divider" alt="Divider" src="/img/group-2301.png" />
                    <div className="frame-40">
                      <div className="frame-31">
                        <img className="ellipse-11" alt="Ellipse" src="/img/icons.png" />
                        <div className="frame-41">
                          <div className="text-wrapper-43">Company Master Report</div>
                          <p className="text-wrapper-44">
                            Verify all the Gaminars you have done, including credits used, client and number of
                            participants
                          </p>
                        </div>
                      </div>
                      <img className="expand-less-2" alt="Expand less" src="/img/icons.png" />
                    </div>
                    <img className="divider-2" alt="Divider" src="/img/group-2301.png" />
                    <div className="frame-40">
                      <div className="frame-31">
                        <img className="ellipse-12" alt="Ellipse" src="/img/icons.png" />
                        <div className="frame-41">
                          <div className="text-wrapper-43">Simulation Report</div>
                          <p className="text-wrapper-44">
                            Verify all the Gaminars you have done, including credits used, client and number of
                            participants
                          </p>
                        </div>
                      </div>
                      <img className="expand-less-3" alt="Expand less" src="/img/icons.png" />
                    </div>
                    <img className="divider-3" alt="Divider" src="/img/group-2301.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-42">
            <div className="frame-43">
              <div className="frame-44">
                <div className="frame-30">
                  <TypeFiArrowLeft />
                  <div className="headline">Back</div>
                </div>
                <div className="frame-45">
                  <div className="frame-46">
                    <TypeDefaultSize
                      avatarHasText={false}
                      avatarSizeXlPlaceholderClassName="avatar-3"
                      className="avatar-2"
                    />
                    <div className="frame-47">
                      <div className="text-wrapper-45">Anusha</div>
                      <div className="anusha-network"> anusha@99x.network</div>
                    </div>
                    <div className="icons-wrapper">
                      <IconEditFillFalse className="icon-edit-fill-false-instance" iconEditFillFalse="/img/icons.png" />
                    </div>
                  </div>
                  <div className="frame-48">
                    <div className="frame-49">
                      <div className="frame-50">
                        <div className="frame-51">
                          <div className="text-wrapper-46">Credits</div>
                          <IconHelpFillFalse
                            className="icon-help-fill-false-instance"
                            iconHelpFillFalse="/img/icons.png"
                          />
                        </div>
                        <div className="text-wrapper-47">2000</div>
                      </div>
                      <Button
                        buttonBaseClassName="button-2"
                        className="button-instance"
                        size="small"
                        text="Upgrade"
                        textClassName="button-3"
                        type="primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="log-out">
                <div className="layout">
                  <IconLogoutFill className="icon-logout-fill-false" iconLogoutFill="/img/icons.png" />
                  <div className="label">Log out</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="group-2" alt="Group" src="/img/Group2301.png" />
      </div>
    </div>
  );
};
