/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Checkbox = ({ stateProp, variant, className, statesClassName, states = "/img/icon-1.svg" }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "enabled",
    variant: variant || "primary",
  });

  return (
    <div
      className={`checkbox state-1-${state.state} ${state.variant} ${className}`}
      onClick={() => {
        dispatch("click");
      }}
    >
      <img
        className={`states ${statesClassName}`}
        alt="States"
        src={
          ["diabled", "enabled"].includes(state.state)
            ? states
            : state.variant === "primary" && state.state === "hover"
            ? "/img/states-24.svg"
            : state.variant === "primary" && state.state === "focused"
            ? "/img/states-21.svg"
            : state.variant === "deselected" && state.state === "focused"
            ? "/img/states-20.svg"
            : state.variant === "secondary" && state.state === "focused"
            ? "/img/states-19.svg"
            : state.variant === "primary" && state.state === "pressed"
            ? "/img/states-18.svg"
            : state.variant === "deselected" && state.state === "pressed"
            ? "/img/states-17.svg"
            : "/img/states-1.svg"
        }
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "click":
      return {
        ...state,
        state: "hover",
      };
  }

  return state;
}

Checkbox.propTypes = {
  stateProp: PropTypes.oneOf(["enabled", "focused", "pressed", "diabled", "hover"]),
  variant: PropTypes.oneOf(["primary", "deselected", "secondary"]),
  states: PropTypes.string,
};
