/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Avatar } from "../Avatar";
import "./style.css";

export const TypeDefaultSize = ({
  className,
  avatarSizeXlPlaceholderClassName,
  avatarAvatarOnlineSizeXsOnlineFalseClassName,
  avatarHasText,
}) => {
  return (
    <div className={`type-default-size ${className}`}>
      <Avatar
        avatarOnlineSizeXsOnlineFalseClassName={avatarAvatarOnlineSizeXsOnlineFalseClassName}
        className={avatarSizeXlPlaceholderClassName}
        hasText={avatarHasText}
        placeholder={false}
        size="md"
        state="default"
        statusIcon="online-indicator"
        text={false}
      />
    </div>
  );
};

TypeDefaultSize.propTypes = {
  avatarHasText: PropTypes.bool,
};
