/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconHelpFillFalse = ({ className, iconHelpFillFalse = "/img/icon-help-fill-false.png" }) => {
  return <img className={`icon-help-fill-false ${className}`} alt="Icon help fill false" src={iconHelpFillFalse} />;
};

IconHelpFillFalse.propTypes = {
  iconHelpFillFalse: PropTypes.string,
};
