/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons18 = ({ className }) => {
  return (
    <svg
      className={`icons-18 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="25"
        id="mask0_30_23539"
        maskUnits="userSpaceOnUse"
        // style="mask-type:alpha"
        width="24"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="24" width="24" y="0.5" />
      </mask>
      <g className="g" mask="url(#mask0_30_23539)">
        <path
          className="path"
          d="M12 16.6042C13.1186 16.6042 14.0695 16.2054 14.8528 15.4076C15.6355 14.6093 16.0268 13.6401 16.0268 12.5C16.0268 11.3599 15.6355 10.3907 14.8528 9.59238C14.0695 8.79464 13.1186 8.39577 12 8.39577C10.8814 8.39577 9.9305 8.79464 9.1472 9.59238C8.36451 10.3907 7.97316 11.3599 7.97316 12.5C7.97316 13.6401 8.36451 14.6093 9.1472 15.4076C9.9305 16.2054 10.8814 16.6042 12 16.6042ZM12 14.8485C11.3587 14.8485 10.8143 14.6205 10.3669 14.1645C9.91946 13.7085 9.69575 13.1536 9.69575 12.5C9.69575 11.8464 9.91946 11.2915 10.3669 10.8355C10.8143 10.3795 11.3587 10.1515 12 10.1515C12.6413 10.1515 13.1857 10.3795 13.6331 10.8355C14.0805 11.2915 14.3043 11.8464 14.3043 12.5C14.3043 13.1536 14.0805 13.7085 13.6331 14.1645C13.1857 14.6205 12.6413 14.8485 12 14.8485ZM12 19.5C9.77778 19.5 7.76078 18.8652 5.94899 17.5956C4.13661 16.3267 2.82028 14.6281 2 12.5C2.82028 10.3719 4.13661 8.67303 5.94899 7.40345C7.76078 6.13448 9.77778 5.5 12 5.5C14.2222 5.5 16.2395 6.13448 18.0519 7.40345C19.8637 8.67303 21.1797 10.3719 22 12.5C21.1797 14.6281 19.8637 16.3267 18.0519 17.5956C16.2395 18.8652 14.2222 19.5 12 19.5ZM12 17.5163C13.6853 17.5163 15.2289 17.0639 16.6309 16.1592C18.0328 15.255 19.1066 14.0353 19.8524 12.5C19.1066 10.9647 18.0328 9.74469 16.6309 8.83993C15.2289 7.93579 13.6853 7.48371 12 7.48371C10.3147 7.48371 8.77107 7.93579 7.36913 8.83993C5.96719 9.74469 4.89336 10.9647 4.14765 12.5C4.89336 14.0353 5.96719 15.255 7.36913 16.1592C8.77107 17.0639 10.3147 17.5163 12 17.5163Z"
          fill="#666666"
        />
      </g>
    </svg>
  );
};
