/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Icons14 } from "../../icons/Icons14";
import { Icons15 } from "../../icons/Icons15";
import { Icons16 } from "../../icons/Icons16";
import { Icons18 } from "../../icons/Icons18";
import "./style.css";

export const InputField = ({
  stateProp,
  property,
  className,
  frameClassName,
  divClassName,
  icon = <Icons18 className="icons-18" />,
  text = "Email Address",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
    property: property || "default",
  });

  return (
    <div
      className={`input-field ${state.state} ${state.property} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={() => {
        dispatch("click");
      }}
    >
      {["default", "input-input"].includes(state.property) && (
        <div className={`email-address ${frameClassName}`}>
          {state.property === "default" && ["default", "hover"].includes(state.state) && <>{text}</>}

          {state.property === "default" && state.state === "selected" && <>|</>}

          {state.property === "input-input" && (
            <>
              <div className="frame">
                <div className="flag" />
                <div className="text-wrapper">+91</div>
                {state.state === "default" && <Icons16 className="icons" />}

                {state.state === "hover" && <Icons15 className="icons" />}

                {state.state === "selected" && <Icons14 className="icons" />}
              </div>
              <img className="line" alt="Line" src="/img/line-73.svg" />
              <div className="text-wrapper">99987115132</div>
            </>
          )}
        </div>
      )}

      {state.property === "input-icon" && (
        <>
          <div className={`div ${divClassName}`}>
            {state.state === "selected" && <>|</>}

            {["default", "hover"].includes(state.state) && <>Password</>}
          </div>
          {icon}
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.property === "default" && state.state === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property: "default",
          state: "hover",
        };

      case "click":
        return {
          property: "default",
          state: "selected",
        };
    }
  }

  if (state.property === "default" && state.state === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          property: "default",
          state: "default",
        };
    }
  }

  if (state.property === "input-icon" && state.state === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property: "input-icon",
          state: "selected",
        };

      case "click":
        return {
          property: "input-icon",
          state: "selected",
        };
    }
  }

  if (state.property === "input-icon" && state.state === "hover") {
    switch (action) {
      case "mouse_enter":
        return {
          property: "input-icon",
          state: "default",
        };

      case "click":
        return {
          property: "default",
          state: "selected",
        };
    }
  }

  if (state.property === "input-input" && state.state === "default") {
    switch (action) {
      case "mouse_enter":
        return {
          property: "input-input",
          state: "hover",
        };

      case "click":
        return {
          property: "input-input",
          state: "selected",
        };
    }
  }

  if (state.property === "input-input" && state.state === "hover") {
    switch (action) {
      case "mouse_leave":
        return {
          property: "input-input",
          state: "default",
        };
    }
  }

  return state;
}

InputField.propTypes = {
  stateProp: PropTypes.oneOf(["selected", "hover", "default"]),
  property: PropTypes.oneOf(["input-input", "input-icon", "default"]),
  text: PropTypes.string,
};
