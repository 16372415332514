/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconEditFillFalse = ({ className, iconEditFillFalse = "/img/icon-edit-fill-false.png" }) => {
  return <img className={`icon-edit-fill-false ${className}`} alt="Icon edit fill false" src={iconEditFillFalse} />;
};

IconEditFillFalse.propTypes = {
  iconEditFillFalse: PropTypes.string,
};
