/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const User9 = ({ className }) => {
  return (
    <svg
      className={`user-9 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.6666 17.5V15.8333C16.6666 14.9493 16.3154 14.1014 15.6903 13.4763C15.0652 12.8512 14.2173 12.5 13.3333 12.5H6.66659C5.78253 12.5 4.93468 12.8512 4.30956 13.4763C3.68444 14.1014 3.33325 14.9493 3.33325 15.8333V17.5M13.3333 5.83333C13.3333 7.67428 11.8409 9.16667 9.99992 9.16667C8.15897 9.16667 6.66659 7.67428 6.66659 5.83333C6.66659 3.99238 8.15897 2.5 9.99992 2.5C11.8409 2.5 13.3333 3.99238 13.3333 5.83333Z"
        stroke="#7F56D9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.66667"
      />
    </svg>
  );
};
