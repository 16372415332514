/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icons22 = ({ className }) => {
  return (
    <svg
      className={`icons-22 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        className="mask"
        height="20"
        id="mask0_23_609"
        maskUnits="userSpaceOnUse"
        // style="mask-type:alpha"
        width="20"
        x="0"
        y="0"
      >
        <rect className="rect" fill="#D9D9D9" height="20" width="20" />
      </mask>
      <g className="g" mask="url(#mask0_23_609)">
        <path
          className="path"
          d="M9.99996 13.4202C10.9321 13.4202 11.7245 13.0878 12.3773 12.423C13.0295 11.7577 13.3557 10.9501 13.3557 10C13.3557 9.04995 13.0295 8.24227 12.3773 7.57698C11.7245 6.9122 10.9321 6.5798 9.99996 6.5798C9.06782 6.5798 8.27538 6.9122 7.62263 7.57698C6.97038 8.24227 6.64425 9.04995 6.64425 10C6.64425 10.9501 6.97038 11.7577 7.62263 12.423C8.27538 13.0878 9.06782 13.4202 9.99996 13.4202ZM9.99996 11.9571C9.46553 11.9571 9.01189 11.7671 8.63903 11.3871C8.26618 11.0071 8.07975 10.5447 8.07975 10C8.07975 9.4553 8.26618 8.99294 8.63903 8.61292C9.01189 8.2329 9.46553 8.04289 9.99996 8.04289C10.5344 8.04289 10.988 8.2329 11.3609 8.61292C11.7337 8.99294 11.9202 9.4553 11.9202 10C11.9202 10.5447 11.7337 11.0071 11.3609 11.3871C10.988 11.7671 10.5344 11.9571 9.99996 11.9571ZM9.99996 15.8333C8.14811 15.8333 6.46727 15.3043 4.95745 14.2464C3.44714 13.1889 2.3502 11.7734 1.66663 10C2.3502 8.22657 3.44714 6.81086 4.95745 5.75288C6.46727 4.6954 8.14811 4.16667 9.99996 4.16667C11.8518 4.16667 13.5329 4.6954 15.0432 5.75288C16.553 6.81086 17.6497 8.22657 18.3333 10C17.6497 11.7734 16.553 13.1889 15.0432 14.2464C13.5329 15.3043 11.8518 15.8333 9.99996 15.8333ZM9.99996 14.1802C11.4044 14.1802 12.6907 13.8033 13.859 13.0493C15.0273 12.2958 15.9222 11.2794 16.5436 10C15.9222 8.72059 15.0273 7.70391 13.859 6.94995C12.6907 6.19649 11.4044 5.81976 9.99996 5.81976C8.59553 5.81976 7.30918 6.19649 6.1409 6.94995C4.97262 7.70391 4.07776 8.72059 3.45634 10C4.07776 11.2794 4.97262 12.2958 6.1409 13.0493C7.30918 13.8033 8.59553 14.1802 9.99996 14.1802Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
